import {BaseStore} from "./base.store";
import {RootStore} from "./index";
import {makeObservable, observable} from "mobx";

export class AdminStore extends BaseStore {
    public savedPortalUrl: string = '/';

    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this, {
            savedPortalUrl: observable,
        });
    }

    public setSavedPortalUrl(savedPortalUrl: string) {
        this.savedPortalUrl = savedPortalUrl;
    }

    get getSavedPortalUrl(): string {
        return this.savedPortalUrl;
    }
}