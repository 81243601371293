export const WIDGET_NAME_MAP = {
    SECURITY_ISSUES_IMPORTANCE: 'securityIssuesImportance',
    ISSUES_CATEGORIES: 'issuesCategories',
    TOTAL_PROTECTED_DEVICES: 'totalProtectedDevices',
    DEVICES_AT_RISK: 'devicesAtRisk',
    TOTAL_ISSUES: 'totalIssues',
    SECURITY_ISSUES_FULL: 'securityIssuesFull',
    SECURITY_ISSUES_TOP3: 'securityIssuesTop3',
    DEVICES_FOR_ISSUE: 'devicesForIssue',
    RECOMMENDED_ACTIONS: 'recommendedActions',
    DESTINATIONS_FOR_ISSUE: 'destinationsForIssue',
};