import React from 'react';
import { useStores } from '../store';
import CustomModal from '../shared/custom-modal/custom-modal';
import { ERROR_MSG } from '../../utils/constants';
import { observer } from 'mobx-react-lite';
import { v4 as uuidv4 } from 'uuid';

const ErrorModal: React.FC = observer(() => {
    const { errorModalStore } = useStores();

    const handleClose = () => {
        errorModalStore.closeModal();
    };

    return (
        <CustomModal
            isOpen={errorModalStore.isOpen}
            width={600}
            title="Error"
            onOk={handleClose}
            cancelButtonProps={{ className: 'hide' }}
            onCancel={handleClose}>
            {(errorModalStore.errorMessage || ERROR_MSG).split('\n').map((item, key) => {
                return <div key={uuidv4()}>{item}</div>
            })}
        </CustomModal>
    );
});

export default ErrorModal;
