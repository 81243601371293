import React from 'react';
import TrendArrow from '../../shared/trend-arrow/trend-arrow';

interface TrendDataCellProps {
    count: number;
    trend: string;
    percentChange: number;
    className: string;
}

const TrendDataCell: React.FC<TrendDataCellProps> = ({ count, trend, percentChange, className }) => {
    return (
        <div className={`cell ${className}`}>
            <div className={`${className}-number`}>{count?.toLocaleString('en-US')}</div>
            <TrendArrow trend={trend} percentChange={percentChange} />
        </div>
    );
};

export default TrendDataCell;
