import React, {useState} from "react";
import {Button, Checkbox} from "antd";
import './cc-accounts.scss';
import {CheckboxChangeEvent} from "antd/es/checkbox";
import {ReactComponent as Delete} from '../../../../../assets/svg/delete.svg';

export const ControlCenterAccountsComponent = () => {
    const [accounts, setAccounts] = useState([{active: true, id: '', name: ''}]);

    function onAddAccountClick() {
        setAccounts(prevState => [...prevState, {active: true, id: '', name: ''}]);
    }

    const onCheckboxChange = (id: string, e: CheckboxChangeEvent) => {
        const {checked} = e.target;
        setAccounts(accounts.map(account => account.id === id ? {...account, active: checked} : account));
    }

    const onInputChange = (index: number, field: string, value: string) => {
        setAccounts(accounts.map((account, i) => i === index ? {...account, [field]: value} : account));
    }

    function onDeleteClick(index: number) {
        setAccounts(accounts.filter((_, i) => i !== index));
    }

    return (
        <div className='accounts-container'>
            <div className='accounts-header'>
                <span>Active?</span>
                <div className='account-info'>
                    <span>Account ID</span>
                    <span>Account Name</span>
                </div>
            </div>
            {accounts.map((account, index) => (
                <div className='account-row' key={account.id}>
                    <Checkbox
                        className="checkbox"
                        checked={account.active}
                        onChange={(e) => onCheckboxChange(account.id, e)}
                    />
                    <div className='text-inputs'>
                        <input type="text" value={account.id} name='id'
                               onChange={(e) => onInputChange(index, 'id', e.target.value)}/>

                        <input type="text" value={account.name} name='name'
                               onChange={(e) => onInputChange(index, 'name', e.target.value)}/>
                    </div>
                    <div className={`delete ${accounts.length > 1 ? '' : 'hidden'}`}>
                        {accounts.length > 1 && <Delete onClick={() => onDeleteClick(index)}/>}
                    </div>
                </div>
            ))}
            <div className='add-account-btn'>
                <Button type="primary" onClick={onAddAccountClick}>Add account</Button>
            </div>
        </div>
    );
};