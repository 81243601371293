import axiosService from './axios.service';
import {OemDetails} from '../dtos/oem-details.dto';
import {UIBE_PREFIX} from '../utils/constants';
import {OemOnboardingDetails} from "../dtos/oem-onboarding-details.dto";

class CustomerService {

    public getOemList = async (singleCustomerName: string): Promise<OemDetails[]> => {
        let url = `${UIBE_PREFIX}/oem`;
        if (singleCustomerName)  {
            url += `?customerName=${singleCustomerName}`;
        }
        const res = await axiosService.get(url);
        return res?.data;
    }

    public getOemOnboardingDetailsList = async (): Promise<OemOnboardingDetails[]> => {
        const res = await axiosService.get(`${UIBE_PREFIX}/oem-configuration`);
        return res?.data;
    }

    public saveOemConfiguration = async (data: any): Promise<any> => {
        const res = await axiosService.post(`${UIBE_PREFIX}/oem-configuration`, data);
        return res?.data;
    }

    public startOemOnboarding = async (oemInternalName: string): Promise<any> => {
        const res = await axiosService.post(`${UIBE_PREFIX}/oem-onboarding`, {oemInternalName});
        return res?.data;
    }
}

const customerService = new CustomerService();
export default customerService;
