import {Button} from "antd";
import React, {useEffect} from "react";
import {useNavigate} from "react-router";
import {useStores} from "../../../store";
import './admin-customers.scss';
import {observer} from "mobx-react-lite";
import LoaderComponent from "../../../shared/loader/loader-component";
import {getColumns} from "./admin-customers-columns";

export const AdminCustomersComponent = observer(() => {
    const navigate = useNavigate();
    const {customerStore, uxStore} = useStores();

    const columns = getColumns();

    useEffect(() => {
        customerStore.getOemOnboardingDetailsList().then();
    }, []);

    function getCellRender(item: any, column: any) {
        if (column.render) {
            return column.render(item[column.dataIndex], item);
        }
        return item[column.dataIndex];
    }

    return (<>
            <div className='customers-container'>
                <div className='customers-header'>
                    <div className='header-title'>Customer details</div>
                    <Button className={'secondary-btn'}
                            type="primary"
                            onClick={() => navigate('../customer-edit')}>
                        Add Customer</Button>
                </div>
                <div className='table-container'>
                    <div className='table-row'>
                        {columns.map(column => (
                            <div key={column.key} className='table-column'
                                 style={{width: column.width}}>{column.title}</div>
                        ))}
                    </div>
                    {customerStore.oemOnboardingDetails?.map(item => (
                        <div className='table-row' key={item.id}>
                            {columns.map(column => (
                                <div key={column.key} className='table-cell'
                                     style={{
                                         width: column.width,
                                         textAlign: column.ignoreTextAlign ? 'left' : 'center'
                                     }}>
                                    {getCellRender(item, column)}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
            {uxStore.showLoader && <LoaderComponent/>}
        </>
    );
});