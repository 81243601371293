import React, { useEffect, useState, useRef, useMemo } from 'react';
import moment from 'moment';
import { ReactComponent as CloseIcon } from '../../../assets/svg/x-blue.svg';
import { StatusColors } from '../security-dashboard/security-dashboard.constant';
import RecommendedAction from '../security-dashboard/recommended-action/recommended-action';
import { Action, IssueDetail, TimeRange } from '../../../utils/model';
import './security-issue-details.scss';
import Speedometer from '../speedometer/speedometer';
import AffectedDevices from '../affected-devices/affected-devices';
import widgetsService from '../../../services/widgets.service';
import { WIDGET_NAME_MAP } from '../../../utils/widget-map';
import { fillMissingDates } from '../../../utils/parse';
import CollapsibleFilterableList from '../collapsible-filterable-list/collapsible-filterable-list';
import ApnRatTypeUseCase from '../apn-rat-type-use-case/apn-rat-type-use-case';

interface SecurityIssueDetailsProps {
    issue: IssueDetail;
    customerName: string;
    dateRangeObj: TimeRange;
    onClose: () => void;
}

const SecurityIssueDetails: React.FC<SecurityIssueDetailsProps> = ({ issue, customerName, dateRangeObj, onClose }) => {
    const [deviceList, setDeviceList] = useState<{ imei: string, imsi: string }[]>([]);
    const [assetList, setAssetList] = useState<{ hostname: string, ip: string, port: string }[]>([]);
    const [isClosing, setIsClosing] = useState(false);
    const [isLoadingDevices, setIsLoadingDevices] = useState(true);
    const [isLoadingAssets, setIsLoadingAssets] = useState(true);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (issue.affected_devices > 0) {
            setIsLoadingDevices(true);
            widgetsService.getWidgetData(customerName, WIDGET_NAME_MAP.DEVICES_FOR_ISSUE,
                dateRangeObj, { extraParameters: { id: issue.id } }
            ).then(res => {
                setDeviceList(res);
                setIsLoadingDevices(false);
            }).catch(() => setIsLoadingDevices(false));
        }
    }, [issue.id, dateRangeObj, issue.affected_devices]);

    useEffect(() => {
        if (issue.affected_destinations > 0) {
            setIsLoadingAssets(true);
            widgetsService.getWidgetData(customerName, WIDGET_NAME_MAP.DESTINATIONS_FOR_ISSUE,
                dateRangeObj, { extraParameters: { id: issue.id } }
            ).then(res => {
                setAssetList(res);
                setIsLoadingAssets(false);
            }).catch(() => setIsLoadingAssets(false));
        }
    }, [issue.id, dateRangeObj, issue.affected_destinations]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const { target } = event;
            let className = '';
            if (target instanceof HTMLElement) {
                className = target.className;
            }
            if (containerRef.current && !containerRef.current.contains(target as Node) && !className.includes('cell')) {
                handleClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    const handleClose = () => {
        setIsClosing(true);
        setTimeout(() => {
            setIsClosing(false);
            onClose();
        }, 400);
    };

    const recommendedAction: Action = useMemo(() => {
        return {
            id: issue.id,
            recommended_action: issue.recommended_action,
            severity: issue.severity
        }
    }, [issue.recommended_action]);

    return (
        <div className={`security-issue-details ${isClosing ? 'closing' : 'open'}`} ref={containerRef}>
            <div className="security-issue-header">
                <div className="security-issue-details-title">{issue.name}</div>
                <button onClick={handleClose}><CloseIcon /></button>
            </div>
            <div className="details">
                <div className="issue-details-time">{moment(issue.last_date).format('MMMM D, YYYY, h:mm A z')}</div>
                <div className="issue-details-widgets">
                    <div className="importance-status widget" style={{ color: StatusColors[issue.severity].color }}>
                        <Speedometer status={issue.severity} />
                    </div>
                    <div className="issue-details-trend widget">
                        <AffectedDevices
                            deviceNumber={issue.affected_devices > 0 ? deviceList.length : assetList.length}
                            percent={issue.affected_devices > 0 ? issue.device_percent_change : issue.destination_percent_change}
                            trendDirection={(issue.affected_devices > 0 ? issue.device_trend : issue.destination_trend) as 'up' | 'down'}
                            points={fillMissingDates(issue.affected_devices > 0 ? issue.devices_date_points : issue.destinations_date_points, dateRangeObj)}
                            title={issue.affected_devices > 0 ? "Affected Devices" : "Affected Assets"}
                        />
                    </div>
                </div>
                <div className="issue-details-desc">{issue.long_description}</div>
                <div className="issue-details-recommended-action">
                    <div className="issue-details-recommended-title">Recommended Action</div>
                    <RecommendedAction action={recommendedAction} />
                </div>
                <div className="issue-details-apns">
                    <ApnRatTypeUseCase
                        ratTypesPerApn={issue.rat_types_per_apn}
                        useCaseNames={issue.use_cases}
                        dateObj={dateRangeObj} />
                </div>
                {issue.affected_devices > 0 && (
                    <div className="device-section">
                        <CollapsibleFilterableList
                            items={deviceList}
                            title="Devices"
                            searchPlaceholder="Search devices by IMEI or IMSI.."
                            trend={issue.device_trend as 'up' | 'down'}
                            percentChange={issue.device_percent_change}
                            isLoading={isLoadingDevices}
                            fields={['imei', 'imsi']}
                            titles={['IMEI', 'IMSI']}
                        />
                    </div>)
                }
                {issue.affected_destinations > 0 && (
                    <div className="asset-section">
                        <CollapsibleFilterableList
                            items={assetList}
                            title="Assets"
                            searchPlaceholder="Search assets by Destination, IP, or Port.."
                            trend={issue.destination_trend as 'up' | 'down'}
                            percentChange={issue.destination_percent_change}
                            isLoading={isLoadingAssets}
                            fields={['hostname', 'ip', 'port']}
                            titles={['Destinations', 'IPs', 'Ports']}
                        />
                    </div>)
                }
            </div>
        </div>
    );
};

export default SecurityIssueDetails;
