import {BaseStore} from './base.store';
import {action, computed, makeObservable, observable, runInAction} from 'mobx';
import customerService from '../../services/customer.service';
import {RootStore} from './index';

export class CustomerStore extends BaseStore {
    public data = [];
    public activeOems = [];
    public selectedCustomer: any;
    public isError = false;
    public oemOnboardingDetails = [];

    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this, {
            data: observable,
            activeOems: observable,
            load: action,
            selectedCustomer: observable,
            setSelectedCustomer: action,
            isError: observable,
            customerRatTypes: computed,
            oemOnboardingDetails: observable,
            saveOemConfiguration: action,
            getOemOnboardingDetailsList: action
        });
    }

    public async load(customerName?: string) {
        try {
            this.setIsLoading(true);
            this.isError = false;
            const res = await customerService.getOemList(this.rootStore.userStore.singleCustomerName);
            const oemPreferencesList = [...res].sort((a, b) => a.label.localeCompare(b.label));
            runInAction(() => {
                this.data = oemPreferencesList;
                this.activeOems = oemPreferencesList.filter(a => a.isActive);
                this.selectedCustomer = (customerName && this.activeOems.find((c) => c.name === customerName)) ||
                    this.activeOems[0];
                this.setIsLoading(false);
            });
        } catch (e) {
            runInAction(() => {
                this.isError = true;
                this.setIsLoading(false);
            });
        }
    }

    public setSelectedCustomer(customerName) {
        this.selectedCustomer = this.activeOems.find((oem => oem.name === customerName));
    }

    get customerRatTypes() {
        return this.selectedCustomer?.ratTypeList?.map((ratType: string) => ratType.split('_')[1]) || [];
    }

    public async getOemOnboardingDetailsList() {
        try {
            this.setIsLoading(true);
            this.isError = false;
            const res = await customerService.getOemOnboardingDetailsList();
            runInAction(() => {
                this.oemOnboardingDetails = res;
                this.setIsLoading(false);
            });
        } catch (e) {
            runInAction(() => {
                this.isError = true;
                this.setIsLoading(false);
            });
        }
    }

    public async saveOemConfiguration(data) {
        data.oemConfiguration.oemDetails.ratTypeList = data.oemConfiguration.oemDetails.ratTypeList.join(',');
        try {
            this.setIsLoading(true);
            await customerService.saveOemConfiguration(data);
            runInAction(() => {
                this.setIsLoading(false);
            })
        } catch (e) {
            runInAction(() => {
                this.setIsLoading(false);
            })
            throw e;
        }
    }

    public async startOemOnboarding(oemInternalName: string) {
        try {
            this.setIsLoading(true);
            return await customerService.startOemOnboarding(oemInternalName);
        } finally {
            this.setIsLoading(false);
        }
    }
}
