import { observer } from 'mobx-react-lite';
import './side-menu.scss';
import { Link } from 'react-router-dom';
import { useStores } from '../store';
import { MenuOptions } from '../../utils/enums';
import { Dropdown, Menu } from 'antd';
import Navbar from '../app-content/use-cases-settings/use-case-management/nav-bar/nav-bar';
import { AdminNavbar } from "../app-content/admin/admin-nav-bar/admin-nav-bar";

export const SideMenu = observer(() => {
    const {customerStore, uxStore, userStore} = useStores();

    const menuItems = [
        {
            key: MenuOptions.DASHBOARD,
            label: 'Dashboard',
            link: '/dashboard',
        },
        {
            key: MenuOptions.ANOMALIES,
            label: 'Anomalies',
            link: '/anomalies',
            dropdown: <Navbar disableInactive={true}
                              relativePath={`${customerStore.selectedCustomer?.name}/anomalies`}/>
        },
        {
            key: MenuOptions.INSIGHTS,
            label: 'Insights',
            link: '/insights'
        },
        {
            key: MenuOptions.SETTINGS,
            label: 'Settings',
            link: '/settings'
        },
        {
            key: MenuOptions.ADMIN,
            label: 'Admin',
            dropdown: <AdminNavbar/>,
            hide: !userStore.isAdminRole
        }];

    return (
        <div className="menu-container">
            <Menu mode="horizontal" theme="dark" className="side-menu" selectedKeys={[uxStore.selectedMenuItem]}>
                {menuItems.filter(item => !item.hide).map(item => (
                    <Menu.Item key={item.key}>
                        {item.dropdown ? (
                            <Dropdown
                                overlay={item.dropdown}
                                overlayClassName="sub-side-menu"
                                trigger={['click', 'hover']}>
                                <Link to={item.link ? `${customerStore.selectedCustomer?.name}${item.link}` : null}>
                                    {item.label}
                                </Link>
                            </Dropdown>
                        ) : (
                            <Link to={`${customerStore.selectedCustomer?.name}${item.link}`}>{item.label}</Link>
                        )}
                    </Menu.Item>
                ))}
            </Menu>
        </div>
    );
});
