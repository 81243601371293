import React, { useEffect } from 'react';
import './App.css';
import { Header } from './components/header/header';
import { Route, Routes, useNavigate } from 'react-router';
import AppContent from './components/app-content/app-content';
import LoaderComponent from './components/shared/loader/loader-component';
import { useStores } from './components/store';
import { observer } from 'mobx-react-lite';
import { WithAuthorization } from './components/with-autorization/with-authorization';
import { ErrorComponent } from './components/errors/error-component';
import { useLocation } from 'react-router-dom';
import { ErrorCode } from './utils/enums';
import { AdminContentComponent } from "./components/app-content/admin/admin-content";
import { AdminHeader } from "./components/app-content/admin/admin-header";

const AuthorizedAppContent = WithAuthorization(AppContent);

export const App: React.FC = observer(() => {

    const {userStore, errorModalStore} = useStores();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!userStore.isAuthenticated) {
            userStore.load();
        }
    }, []);

    useEffect(() => {
        if (location.state?.reload) {
            userStore.load();
        }
    }, [location.state]);

    useEffect(() => {
        if (errorModalStore.errorStatus === ErrorCode.UNAUTHORIZED_403) {
            navigate('error', {replace: true});
        }
    }, [errorModalStore.errorStatus]);

    if (userStore.isLoading || errorModalStore.errorStatus === ErrorCode.UNAUTHENTICATED_401) {
        return <LoaderComponent/>;
    }

    return (
        <Routes>
            <Route path="/*" element={userStore.isAuthenticated && <>
                <Header/>
                <div className="content-container">
                    <AuthorizedAppContent isAuthorized={userStore.isEditorRole}
                                          isDestinationsAuthorized={userStore.isUnauthorizedDestinationsEditable}/>
                </div>
            </>}>
            </Route>
            <Route path="/admin/*" element={userStore.isAuthenticated && <>
                <AdminHeader/>
                <div className="admin-content-container">
                    <AdminContentComponent/>
                </div>
            </>}>
            </Route>
            <Route path='/error' element={<ErrorComponent/>}/>
        </Routes>
    );
});

export default App;