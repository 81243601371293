import {OnboardingStatus} from "../../../../utils/enums";
import {CheckOutlined} from '@ant-design/icons';
import {ReactComponent as PencilIcon} from '../../../../assets/svg/pencil.svg';
import {ReactComponent as Info} from '../../../../assets/svg/info.svg';
import {Link} from "react-router-dom";
import React from "react";
import DOMPurify from 'dompurify';
import {Tooltip} from 'antd';
import moment from 'moment';

function buildDefaultColumn(title: string, dataIndex: string, width: string) {
    return {
        title,
        dataIndex,
        key: dataIndex,
        width
    };
}

const OnboardingStatusDisplay: { [key in OnboardingStatus]: string } = {
    [OnboardingStatus.IN_PROGRESS]: 'In Progress',
    [OnboardingStatus.DRAFT]: 'Draft',
    [OnboardingStatus.COMPLETED]: 'Completed',
    [OnboardingStatus.FAILED]: 'Failed'
};

function buildStepsTooltipContent(stepsCompletion: any) {
    return (
        <div>
            {Object.entries(stepsCompletion || {}).map(([key, value]) => (
                <div className='step' key={key}>
                    <span>{`${key}: `}</span>
                    <span>{`${value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : ''}`}</span></div>
            ))}
        </div>
    );
}

export const getColumns = (): any => [
    {
        title: 'Active?',
        dataIndex: 'isActive',
        key: 'isActive',
        render: (isActive: any) => isActive ? <CheckOutlined/> : null,
        width: '6%'
    },
    {
        title: 'Onboarding status',
        dataIndex: 'status',
        key: 'status',
        render: (status: OnboardingStatus, record: any) =>
            <>
                {status == OnboardingStatus.DRAFT ?
                    <Link className="draft"
                          to={`../customer-edit/${record.name}`}>Draft</Link> : OnboardingStatusDisplay[status]}
                <Tooltip title={buildStepsTooltipContent(record.stepsCompletion)} placement={"top"}
                         overlayInnerStyle={{width: '400px', border: '1px solid white'}}>
                    <Info className='info-icon'/>
                </Tooltip>
            </>,
        width: '10%'
    },
    buildDefaultColumn('Customer name', 'label', '10%'),
    buildDefaultColumn('Internal name', 'name', '10%'),
    buildDefaultColumn('Business center name', 'externalName', '10%'),
    buildDefaultColumn('Snowflake name', 'sfName', '10%'),
    buildDefaultColumn('Device location', 'locationType', '7%'),
    buildDefaultColumn('RAT type', 'ratTypeList', '8%'),
    {
        title: 'APNs',
        dataIndex: 'apns',
        key: 'apns',
        render: (apns: string[]) => (
            <>
                {apns?.map((apn) => (
                    <div key={apn} className='apn-item'>{apn}</div>
                ))}
            </>
        ),
        width: '16%',
        ignoreTextAlign: true
    },
    {
        title: 'Settings',
        dataIndex: 'name',
        key: 'settings',
        render: (name: string, record: any) => (record.status == OnboardingStatus.COMPLETED
            && record.isActive) &&
            <Link className="draft" to={`../../${DOMPurify.sanitize(name)}/settings`}>Settings</Link>,
        width: '7%'
    },
    {
        dataIndex: 'name',
        key: 'edit',
        render: (name: string, record: any) => record.status != OnboardingStatus.IN_PROGRESS &&
            <Link className="draft" to={`../customer-edit/${record.name}`}>
                <PencilIcon className='pencil-icon'/>
            </Link>,
        width: '4%'
    }
];