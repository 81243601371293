import './admin-header.scss';
import React, { useEffect, useState } from 'react';
import { ReactComponent as MTADLogo } from '../../../assets/svg/logo-att.svg';
import { ReactComponent as LeftArrow } from '../../../assets/svg/arrow-left.svg';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../store';
import { useNavigate } from 'react-router';
import { convertInitials } from '../../../utils/parse';
import ButtonComponent from "../../shared/button/button-component";

export const AdminHeader = observer(() => {
    const {userStore, adminStore} = useStores();

    const navigate = useNavigate();

    const [userInitials, setUserInitials] = useState('');

    useEffect(() => {
        setUserInitials(convertInitials(userStore.data.displayName));
    }, [userStore.data]);

    return (
        <div className="header">
            <div className="left">
                <div className="logo">
                    <MTADLogo/>
                </div>
                <div className="title">MTAD</div>
                <ButtonComponent
                    text='Back to MTAD portal'
                    onClick={() => navigate(adminStore.getSavedPortalUrl)}
                    icon={LeftArrow}
                />
            </div>
            <div className="right">
                <div className="user">
                    <div className="user-circle">{userInitials}</div>
                </div>
            </div>
        </div>
    );
});
