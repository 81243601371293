import React from 'react';
import { CustomerStore } from './customer.store';
import { UserStore } from './user.store';
import { UseCaseStore } from './use-case.store';
import { ErrorModalStore } from './error-modal.store';
import { InsightsStore } from './insights.store';
import { UXStore } from './ux.store';
import { ExceptionListStore } from './exception-list.store';
import { DashboardStore } from './dashboard.store';
import { AnomaliesStore } from './anomalies.store';
import { TrafficClassStore } from './traffic-class.store';
import {AdminStore} from "./admin.store";

export class RootStore {
    public customerStore: CustomerStore;
    public userStore: UserStore;
    public useCaseStore: UseCaseStore;
    public errorModalStore: ErrorModalStore;
    public insightsStore: InsightsStore;
    public uxStore: UXStore;
    public exceptionListStore: ExceptionListStore;
    public dashboardStore: DashboardStore;
    public anomaliesStore: AnomaliesStore;
    public trafficClassStore: TrafficClassStore;
    public adminStore: AdminStore;

    constructor() {
        this.customerStore = new CustomerStore(this);
        this.userStore = new UserStore(this);
        this.useCaseStore = new UseCaseStore(this);
        this.errorModalStore = new ErrorModalStore(this);
        this.insightsStore = new InsightsStore(this);
        this.uxStore = new UXStore(this);
        this.exceptionListStore = new ExceptionListStore(this);
        this.dashboardStore = new DashboardStore(this);
        this.anomaliesStore = new AnomaliesStore(this);
        this.trafficClassStore = new TrafficClassStore(this);
        this.adminStore = new AdminStore(this);
    }
}

const rootStoreInstance = new RootStore();
export default rootStoreInstance;
export const StoresContext = React.createContext(rootStoreInstance);
export const useStores = () => React.useContext(StoresContext);
