import {observer} from 'mobx-react-lite';
import './customer-edit.scss';
import {Button, Collapse, CollapseProps, message} from "antd";
import React, {useEffect, useState} from "react";
import {ReactComponent as CollapseIcon} from '../../../../assets/svg/chevron-down-circle.svg';
import {CustomerDetailsComponent} from "./customer-details/customer-details";
import {ControlCenterAccountsComponent} from "./cc-accounts/cc-accounts";
import {ApnsComponent} from "./apns/apns";
import {useStores} from "../../../store";
import {OemDetails} from "../../../../dtos/oem-details.dto";
import {OemConfiguration} from "../../../../dtos/oem-configuration.dto";
import {useNavigate} from "react-router";
import {useParams} from "react-router-dom";
import LoaderComponent from "../../../shared/loader/loader-component";
import {CreateAdxDbModal} from "./create-adx-db/create-adx-db-modal";
import CustomModal from "../../../shared/custom-modal/custom-modal";

export const CustomerEditComponent = observer(() => {
    const navigate = useNavigate();
    const {customerStore, uxStore} = useStores();
    const [oemConfiguration, setOemConfiguration] = useState({
        oemDetails: {},
        oemOnboardingStatus: '',
        oemAccountDetailsList: []
    } as OemConfiguration);
    const [enableSave, setEnableSave] = useState(false);
    const [enableDraft, setEnableDraft] = useState(false);
    const {oemName} = useParams<{ oemName?: string }>();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

    const items: CollapseProps['items'] = [
        {
            key: '1',
            label: 'Customer details',
            children: <CustomerDetailsComponent onDetailsChange={setCustomerDetails} oemName={oemName}/>,

        },
        {
            key: '2',
            label: 'Control Center accounts',
            children: <ControlCenterAccountsComponent/>,
        },
        {
            key: '3',
            label: 'APNs',
            children: <ApnsComponent/>,
        },
    ];

    useEffect(() => {
        if (customerStore.oemOnboardingDetails.length == 0) {
            customerStore.getOemOnboardingDetailsList().then();
        }
    }, []);

    useEffect(() => {
        const {oemDetails} = oemConfiguration;
        const isComplete =
            oemDetails.name && oemDetails.label && oemDetails.sfName && oemDetails.locationType?.length > 0 &&
            oemDetails.reportDl?.length > 0 && oemDetails.alertsDl?.length > 0 && oemDetails.ratTypeList?.length > 0;
        setEnableSave(isComplete);
        setEnableDraft(oemDetails.name != '');
    }, [oemConfiguration]);

    function setCustomerDetails(oemDetails: OemDetails) {
        setOemConfiguration({...oemConfiguration, oemDetails});
    }

    function onSaveDraftClick() {
        customerStore.setIsLoading(true);
        customerStore.saveOemConfiguration({oemConfiguration}).then(() => {
                message.success('Draft saved successfully!').then();
                navigate('../customers');
            }
        ).catch(() => {
            message.error('Draft save process failed').then();
        }).finally(() => {
            customerStore.setIsLoading(false);
        });
    }

    function onStartOnboardingClick() {
        customerStore.setIsLoading(true);
        customerStore.saveOemConfiguration({oemConfiguration}).then(() => {
                message.success('Draft saved successfully!').then();
                startOemOnboarding();
            }
        ).catch(() => {
            customerStore.setIsLoading(false);
            setIsErrorModalOpen(true);
        });
    }

    function startOemOnboarding() {
        customerStore.setIsLoading(true);
        customerStore.startOemOnboarding(oemConfiguration.oemDetails.name).then((res: any) => {
            res?.dbFound ? message.success('Oem onboarded successfully!').then() : setIsModalOpen(true);
        }).catch(() => {
            setIsErrorModalOpen(true);
        }).finally(() => {
            customerStore.setIsLoading(false);
        });
    }

    function onCreateAdxDbModalClose(tryAgain: boolean) {
        setIsModalOpen(false);
        if (tryAgain) {
            startOemOnboarding();
        }
    }

    return <>
        <div className='details-container'>
            <div className='details-header'>
                <div className='header-title'>Add customer</div>
                <div className='header-buttons'>
                    <Button className={'secondary-btn'} type="primary"
                            onClick={() => navigate('../customers')}>Cancel</Button>
                    <Button className={'secondary-btn'} type="primary" disabled={!enableDraft}
                            onClick={onSaveDraftClick}>Save Draft</Button>
                    <Button type="primary" disabled={!enableSave}
                            onClick={onStartOnboardingClick}>Start Onboarding</Button>
                </div>
            </div>
            <div>
                <Collapse items={items} defaultActiveKey={['1', '2', '3']} bordered={false}
                          expandIcon={({isActive}) => (
                              <CollapseIcon style={{
                                  transform: isActive ? 'rotate(180deg)' : 'rotate(0deg)',
                                  transition: 'transform 0.2s'
                              }}/>
                          )}/>;
            </div>
        </div>
        {uxStore.showLoader && <LoaderComponent/>}
        {isModalOpen &&
            <CreateAdxDbModal oem={oemConfiguration.oemDetails.name}
                              closeModal={onCreateAdxDbModalClose}/>}
        {isErrorModalOpen &&
            <CustomModal
                isOpen={true} width={750}
                title={`Could not verify ADX database for customer ${oemConfiguration.oemDetails.name}`}
                onOk={() => setIsErrorModalOpen(false)}
                onCancel={() => setIsErrorModalOpen(false)}
                cancelButtonProps={{className: 'hide'}}
                okText="OK">
                <div>Please check the MTAD server and try again later</div>
                <br/><br/><br/>
            </CustomModal>}
    </>;
});