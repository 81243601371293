import React from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { TimeRange } from '../../../utils/model';
import { useStores } from '../../store';
import moment from 'moment/moment';
import { ReactComponent as LinkIcon } from '../../../assets/svg/link.svg';
import './apn-rat-type-use-case.scss';
import { v4 as uuidv4 } from 'uuid';

interface ApnRatTypeUseCaseProps {
    useCaseNames: string;
    ratTypesPerApn: { apn: string, rat_type: string }[];
    dateObj: TimeRange;
}

const ApnRatTypeUseCase: React.FC<ApnRatTypeUseCaseProps> = observer(({ useCaseNames, ratTypesPerApn, dateObj }) => {
    const { anomaliesStore, customerStore, useCaseStore } = useStores();
    const navigate = useNavigate();

    const handleLinkClick = (apn: string, ratType: string, useCase: string) => {
        anomaliesStore.setFilter({
            ...anomaliesStore.filter,
            apn,
            ratType,
            dateRange: {
                from: moment.utc(dateObj.from).toDate(),
                to: moment.utc(dateObj.to).toDate(),
            }
        });

        navigate(`/${customerStore.selectedCustomer.name}/anomalies/${useCase}`);
    };

    const headers = ['APN', 'Network type', 'Link to analysis data'];

    return (
        <div className="apn-rat-type-use-case">
            <h2>Security Issue Analysis</h2>
            <div className="table">
                <div className="table-header">
                    {headers.map((header) => (
                        <span key={uuidv4()}>{header}</span>
                    ))}
                </div>
                <div className="table-content">
                    {ratTypesPerApn.map(({ apn, rat_type }) => (
                        <div className="table-row" key={uuidv4()}>
                            <span>{apn}</span>
                            <span>{rat_type}</span>
                            <div className="link">
                                {useCaseNames?.split(',').map((useCase) => (
                                    useCase && <div className="use-case" key={useCase} onClick={() => handleLinkClick(apn, rat_type, useCase)}>
                                        <LinkIcon />
                                        {useCaseStore.useCaseDisplayValues.get(useCase)?.displayName || useCase}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
});

export default ApnRatTypeUseCase;
