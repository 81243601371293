import { Route, Routes } from "react-router";
import React, {useEffect} from "react";
import { AdminSharedApnsComponent } from "./admin-shared-apns/admin-shared-apns";
import { AdminCustomersComponent } from "./admin-customers/admin-customers";
import { CustomerEditComponent } from "./admin-customers/customer-edit";
import './admin-content.scss'
import {useStores} from "../../store";

export const AdminContentComponent = () => {
    const {useCaseStore} = useStores();

    useEffect(() => {
        useCaseStore.loadTemplate().then();
    }, []);

    return (
        <div className='admin-content'>
            <Routes>
                <Route path='customers' element={<AdminCustomersComponent/>}/>
                <Route path='customer-edit/:oemName?' element={<CustomerEditComponent/>}/>
                <Route path='shared-apns' element={<AdminSharedApnsComponent/>}/>
            </Routes>
        </div>
    );
}