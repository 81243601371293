import React, { useState, useEffect } from 'react';
import { ReactComponent as ExpandIcon } from '../../../assets/svg/chevron-up.svg';
import { ReactComponent as SearchIcon } from '../../../assets/svg/searchbox.svg';
import LoadingWrapper from '../../shared/loading-wrapper/loading-wrapper';
import TrendArrow from '../../shared/trend-arrow/trend-arrow';
import './collapsible-filterable-list.scss';
import { v4 as uuidv4 } from 'uuid';

type ItemStructure = Record<string, string>;

interface CollapsibleFilterableListProps<T extends ItemStructure> {
    items: T[];
    title: string;
    searchPlaceholder: string;
    trend: 'up' | 'down';
    percentChange: number;
    isLoading: boolean;
    fields: (keyof T)[];
    titles: string[];
}

const CollapsibleFilterableList = <T extends ItemStructure>({ items, title, searchPlaceholder, trend, percentChange, isLoading, fields, titles }: CollapsibleFilterableListProps<T>) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredItems, setFilteredItems] = useState(items);
    const [isCollapsed, setIsCollapsed] = useState(true);

    useEffect(() => {
        const filtered = items.filter(item =>
            fields.some(field => item[field].toString().toLowerCase().includes(searchTerm.toLowerCase()))
        );
        setFilteredItems(filtered);
    }, [searchTerm, items, fields]);

    return (
        <div className="collapsible-filterable-list">
            <div className="list-header">
                <div className="list-title">{title}<span>{filteredItems.length}</span></div>
                <div className="list-info">
                    <span>{percentChange}% {trend}</span>
                    <span>
                        <TrendArrow trend={trend} percentChange={percentChange} />
                    </span>
                    <button onClick={() => setIsCollapsed(!isCollapsed)}>
                        <ExpandIcon className={isCollapsed ? 'collapsed' : 'expanded'} />
                    </button>
                </div>
            </div>
            <div className={`list-wrapper ${isCollapsed ? 'collapsed' : ''}`}>
                <div className="search-box">
                    <input
                        type="text"
                        placeholder={searchPlaceholder}
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                    />
                    <SearchIcon className="search-icon" />
                </div>
                <LoadingWrapper isLoading={isLoading} skeletonProps={{ count: items.length }}>
                    <div className="item-list">
                        <div className="item-list-title">
                            {titles.map((title) => (
                                <span key={title}>{title}</span>
                            ))}
                        </div>
                        {filteredItems.map((item) => (
                            <div key={uuidv4()} className="item">
                                {fields.map(field => (
                                    <span key={String(field)}
                                          className={item[field] ? '' : 'na'}>{item[field] || 'N/A'}</span>
                                ))}
                            </div>
                        ))}
                    </div>
                </LoadingWrapper>
            </div>
        </div>
    );
};

export default CollapsibleFilterableList;
