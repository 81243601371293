import {observer} from "mobx-react-lite";
import {useStores} from "../../../store";
import {Link} from "react-router-dom";
import React from "react";
import {AdminMenuOptions} from "../../../../utils/enums";
import './admin-nav-bar.scss';

export const AdminNavbar = observer(() => {
    const {adminStore} = useStores();

    const menuItems = [
        {
            key: AdminMenuOptions.CUSTOMERS,
            label: 'Customers',
            link: 'customers',
        }, {
            key: AdminMenuOptions.APNS,
            label: 'Shared APNs',
            link: 'shared-apns',
        }];

    return (
        <div className="navbar-wrapper">
            <div className={'navbar'}>
                {menuItems.map((item) => (
                    <Link key={item.key} to={`/admin/${item.link}`} className={'nav-item'}
                          onClick={() => adminStore.setSavedPortalUrl(window.location.pathname)}>
                        {item.label}
                    </Link>
                ))}
            </div>
        </div>
    );
});