import React, {useEffect, useState} from "react";
import './customer-details.scss';
import {Radio, Select, SelectProps} from "antd";
import TextArea from "antd/es/input/TextArea";
import {useStores} from "../../../../store";

export const CustomerDetailsComponent = ({onDetailsChange, oemName}) => {
    const {customerStore} = useStores();
    const ratTypeOptions: SelectProps['options'] = [{label: 'LTE', value: 'lte'}, {label: '5G SA', value: '5gsa'}];
    const [formData, setFormData] = useState(null);

    useEffect(() => {
        oemName ? fillFormDataByName() : fillDefaultFormData()
    }, [customerStore.oemOnboardingDetails]);

    useEffect(() => {
        if (formData) {
            onDetailsChange(formData);
        }
    }, [formData]);

    function fillDefaultFormData() {
        setFormData({
            name: '',
            isActive: false,
            label: '',
            sfName: '',
            locationType: 'moving',
            reportDl: ['dl-mtad-notifications@intl.att.com'],
            alertsDl: ['dl-mtad-notifications@intl.att.com'],
            isDemo: false,
            ratTypeList: ['lte'],
            externalName: ''
        });
    }

    function fillFormDataByName() {
        const oem = customerStore.oemOnboardingDetails.find(oem => oem.name == oemName);
        setFormData({
            ...oem,
            locationType: oem?.locationType.toLowerCase(),
            ratTypeList: oem?.ratTypeList.toLowerCase().split(",")
        });
    }

    function onCustomerNameBlur() {
        const {label, name, externalName, sfName} = formData;
        if (name === '') {
            const formattedName = label
                .toLowerCase()
                .replace(/\s+/g, '_')
                .replace(/[^a-z0-9_]/g, '');
            setFormData(prevState => ({...prevState, name: formattedName}));
        }
        if (externalName === '') {
            setFormData(prevState => ({...prevState, externalName: label}));
        }
        if (sfName === '') {
            setFormData(prevState => ({...prevState, sfName: label}));
        }
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let {name, value} = e.target;
        if (name == 'label') {
            value = value.toLowerCase();
        }
        setFormData((prevState: any) => ({...prevState, [name]: value}));
    };

    const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>, field: string) => {
        const {value} = e.target;
        setFormData((prevState: any) => ({...prevState, [field]: value.split('\n')}));
    };

    return (
        formData &&
        <div className='customer-details-container'>
            <div className='row'>
                <span className='label'>Customer name:</span>
                <input className={`large`}
                       name='label' value={formData.label}
                       onChange={handleChange} onBlur={onCustomerNameBlur}/>
            </div>
            <div className='row'>
                <span className='label'>Internal name:</span>
                <input className={`medium ${oemName != null ? 'disabled' : ''}`} name='name'
                       value={formData.name} onChange={handleChange}/>
                <span className='label'>RAT type:</span>
                <div className='select-wrapper'>
                    <Select
                        mode="multiple"
                        allowClear
                        className='medium'
                        placeholder="Please select"
                        defaultValue={formData.ratTypeList}
                        options={ratTypeOptions}
                        style={{width: '100%'}}
                        variant="borderless"
                        onChange={
                        (value) => {
                            setFormData(prevState => ({...prevState, ratTypeList: value.join(",")}))
                        }
                    }
                    />
                </div>
            </div>
            <div className='row'>
                <span className='label'>BC name:</span>
                <input className='medium' name='externalName'
                       value={formData.externalName} onChange={handleChange}/>
                <span className='label'>Location:</span>
                <div>
                    <Radio.Group
                        onChange={(e) => setFormData(prevState => ({...prevState, locationType: e.target.value}))}
                        value={formData.locationType}>
                        <Radio value={'moving'}>Moving</Radio>
                        <Radio value={'fixed'}>Fixed</Radio>
                    </Radio.Group>
                </div>
            </div>
            <div className='row medium'>
                <span className='label'>Snowflake name:</span>
                <input className={`medium ${oemName != null ? 'disabled' : ''}`} name='sfName'
                       value={formData.sfName} onChange={handleChange}/>
            </div>
            <div className='distribution-container'>
                <div className='row distribution-labels'>
                    <span className='distribution-label'>Alerts distribution</span>
                    <span className='distribution-label'>Reports distribution</span>
                </div>
                <div className='row distribution-lists'>
                    <div className='distribution-list'>
                        <TextArea autoSize={{minRows: 5}} defaultValue={formData.alertsDl}
                                  onChange={(e) => handleTextAreaChange(e, 'alertsDl')}/>
                    </div>
                    <div className='distribution-list'>
                        <TextArea autoSize={{minRows: 5}} defaultValue={formData.reportDl}
                                  onChange={(e) => handleTextAreaChange(e, 'reportDl')}/>
                    </div>
                </div>
            </div>
        </div>
    );
};