import React, {useState} from "react";
import './apns.scss';
import {ReactComponent as Delete} from '../../../../../assets/svg/delete.svg';
import {Button} from "antd";

export const ApnsComponent = () => {
    const temp = []
    const [apns, setApns] = useState(temp);
    const columns = [
        {
            title: 'APN type',
            dataIndex: 'type',
            key: 'apnType',
            width: '10%'
        },
        {
            title: 'APN short name',
            dataIndex: 'shortApn',
            key: 'apnShortName',
            width: '32%'
        },
        {
            title: 'APN family',
            dataIndex: 'apnFamily',
            key: 'apnFamily',
            width: '32%'
        },
        {
            title: 'APN category',
            dataIndex: 'category',
            render: (category: any) => category || 'None',
            key: 'apnCategory',
            width: '21%'
        },
        {
            title: '',
            dataIndex: 'shortApn',
            render: (shortApn: any) => <Delete className='delete' onClick={() => onApnDeleteClick(shortApn)}/>,
            key: 'apnDelete',
            width: '5%'
        }
    ];

    function onApnDeleteClick(shortApn: any) {
        setApns(apns.filter(item => item.shortApn !== shortApn));
    }

    function getCellRender(item: any, column: any) {
        if (column.render) {
            return column.render(item[column.dataIndex], item);
        }
        return item[column.dataIndex];
    }

    return (
        <div>
            <div className='apns-table-row'>
                {columns.map(column => (
                    <div key={column.key} className='apns-table-column' style={{width: column.width}}>{column.title}</div>
                ))}
            </div>
            {apns.map(item => (
                <div className='apns-table-row' key={item.shortApn}>
                    {columns.map(column => (
                        <div key={column.key} className='apns-table-cell'
                             style={{width: column.width}}>
                            {getCellRender(item, column)}
                        </div>
                    ))}
                </div>
            ))}
            <div className='apns-buttons'>
                <Button type="primary"
                        >
                    Add dedicated APN</Button>
                <Button type="primary"
                >
                    Assign shared APN</Button>
            </div>
        </div>
    );
}