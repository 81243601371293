import CustomModal from '../../../../shared/custom-modal/custom-modal';
import React, {useState} from 'react';
import {observer} from 'mobx-react-lite';
import {message, Modal} from 'antd';
import createAdxDb from '../../../../../assets/images/create_adx_db.png'
import './create-adx-db-modal.scss';
import {ReactComponent as Copy} from '../../../../../assets/svg/copy_fill.svg';
import {useStores} from "../../../../store";

interface CreateAdxDbModalProps {
    oem: string;
    closeModal: (tryAgain: boolean) => void;
}

export const CreateAdxDbModal: React.FunctionComponent<CreateAdxDbModalProps> = observer(({oem, closeModal}) => {
    const {useCaseStore} = useStores();
    const [imageModalVisible, setImageModalVisible] = useState(false);

    const handleCopy = () => {
        navigator.clipboard.writeText(`db_oem_events_${oem}`).then();
        message.success('database name copied to clipboard').then();
    };

    return (
        <>
            <CustomModal
                isOpen={true}
                width={750}
                title={`Create an ADX database for customer ${oem}`}
                onOk={() => closeModal(true)}
                onCancel={() => closeModal(false)}
                cancelButtonProps={{className: 'hide'}}
                okText="Try Again">
                <div>Please use <a href={useCaseStore?.model?.azureDatabases} target="_blank" rel="noopener noreferrer">
                    Azure portal</a> to create an ADX database.
                </div>
                <div className='copy-row'>The database name should be: {`db_oem_events_${oem}`}.
                    <Copy className='copy-btn' onClick={handleCopy}/>
                </div>
                <div className='instructions-container'>
                    <div className='instructions'>
                        <div>Use the following parameters:</div>
                        <div>Retention period (in days): 180</div>
                        <div>Cache period (in days): 180</div>
                    </div>
                    <button className='instructions'
                            onClick={() => setImageModalVisible(true)} onKeyDown={() => setImageModalVisible(false)}>
                        <img src={createAdxDb} alt="Example" className='create-db-img'/>
                    </button>
                </div>
            </CustomModal>
            <Modal
                open={imageModalVisible} onCancel={() => setImageModalVisible(false)}
                footer={null} width={800} closable={false}>
                <button className='img-zoom'
                        onClick={() => setImageModalVisible(false)} onKeyDown={() => setImageModalVisible(false)}>
                    <img src={createAdxDb} alt="Example" style={{width: '100%'}}/>
                </button>
            </Modal>
        </>
    );
});
